<template>
  <div class="home">
    <a href="mailto:zdravko@pernikov.com" class="email">
      <img src="@/assets/paper-plane-regular.svg" alt="Send email" />
    </a>
    <transition name="fade-left" appear>
      <div class="me">
        <img src="@/assets/me.png" alt="Me" />
        <transition name="fade-right">
          <p class="bubble" :class="`bubble--${active % 2}`" v-if="message">
            <strong>
              <span>{{ message }}</span
              ><span class="underline">_</span>
            </strong>
          </p>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      message: "",
      messages: [
        "Hello",
        "I'm Zdravko Pernikov",
        "Nice to meet you",
        "What brings you here?",
      ],
      active: 0,
      interval: null,
      timeout: 1500,
      typeSpeed: 150,
    };
  },
  methods: {
    startTyping() {
      setTimeout(() => {
        this.interval = setInterval(() => {
          if (this.message.length < this.messages[this.active].length) {
            console.log(this.active);
            this.message += this.messages[this.active][this.message.length];
          } else {
            this.stopTyping();
            setTimeout(() => {
              if (this.active < this.messages.length - 1) {
                this.active++;
              } else {
                this.active = 0;
              }

              this.message = "";

              this.startTyping();
            }, this.timeout);
          }
        }, this.typeSpeed);
      }, this.timeout);
    },
    stopTyping() {
      clearInterval(this.interval);
    },
  },
  mounted() {
    this.startTyping();
  },
  destroyed() {
    this.stopTyping();
  },
};
</script>

<style lang="scss" scoped>
.home {
  align-items: center;
  // background-color: rgb(126 49 82);
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  justify-content: center;
}

.email {
  align-items: flex-start;
  display: flex;
  height: 30px;
  justify-content: flex-end;
  position: fixed;
  right: 12px;
  top: 10px;
  width: 30px;
  transition: all 0.3s;
  z-index: 1;
  img {
    width: 25px;
  }
  &:hover {
    opacity: 0.8;
    transform: translateX(-5px) translateY(5px);
  }
}

.me {
  align-items: center;
  // background-color: #f4f2f2;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    height: 30vw;
    width: 30vw;
  }
}

.bubble {
  background-color: #f4f2f2;
  border: 3px solid #7e3152;
  border-top: 1px solid #7e3152;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  bottom: 50%;
  color: #7e3152;
  font-size: 16px;
  padding: 20px;
  position: absolute;
  left: 85%;
  white-space: nowrap;
  &.bubble--0 {
    box-shadow: 3px 3px 0 #73ffe7;
  }
  &.bubble--1 {
    box-shadow: 3px 3px 0 #fd8284;
  }
}

.underline {
  animation: blink 0.5s infinite;
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-left-enter-active,
.fade-left-leave-active,
.fade-right-enter-active,
.fade-right-leave-active {
  transition: all 0.5s;
}
.fade-left-enter,
.fade-left-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}
.fade-right-enter,
.fade-right-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

@media screen and (max-width: 1280px) {
  .me {
    img {
      height: 50vw;
      width: 50vw;
    }
  }
}

@media screen and (max-width: 768px) {
  .me {
    height: 100vh;
    width: 100%;
    img {
      height: 75vw;
      width: 75vw;
    }
  }
  .bubble {
    bottom: 10px;
    left: 10px;
    // top: 100%;
    width: calc(100% - 20px);
  }
}
</style>
